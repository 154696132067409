/* width */
::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff; 
  border-radius: 10px;
  background-color: #c8c8c8;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #21252993;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #21252993;
}

.hide-scroll {
  ::-webkit-scrollbar {
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    display: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    display: none;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    display: none;
  }
}