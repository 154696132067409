$primary: #230028;
$secondary: #3D0046;
// $success:  darkgreen;
$info: #EFEFEF;
// $warning:  #6A7B6F;
$light: #FBE3FF;
$danger: #FF0000;
$dark:  dimgrey;




@import '../../../node_modules//bootstrap/scss/bootstrap.scss';




.nav-pills .nav-link {
    &.active {
        // @extend .bg-white;
        // @extend .bg-opacity-25;
        @extend .rounded-pill;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 50px -10px, rgba(0, 0, 0, 0.04) 0px 10px 50px -10px;
        background: #ffffff20 !important;
        border: 1px solid #ffffff20;
        transition: all 0.5s ease;
    }

    &:not(.active) {
        @extend .text-white;
        @extend .opacity-50;
        border: 1px solid #ffffff00;
        transition: all 0.5s ease;
    }
}

.nav-pills .nav-link:hover {
    color: #fff;
    opacity: 1 !important;
}

// .nav-pills .nav-link {
//     &.active {
//         @extend .bg-white;
//         @extend .bg-opacity-25;
//         transition: all 0.1s ease-in-out;
//     }

//     &:not(.active) {
//         @extend .text-white;
//         @extend .opacity-50;
//     }
// }