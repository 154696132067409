html,
body,
#root {
  margin: 0;
  height: 100vh;
}

.pagesBack {
  background-image: url("../images/pagesBack.png");
  /* z-index: -1; */
}

p {
  margin: 0;
}

.rounded-bottom-6 {
  border-radius: 0rem 0rem 3rem 3rem;
}
.x{
  color: #0FA958;
}

.tox-promotion, .tox-statusbar__branding{
  display: none;
}

.cursor-pointer{
  cursor: pointer;
}